import { format } from 'date-fns'
import { DATE_FORMAT } from '../../date-formatting.js'
import { configApi } from '../../http-client.js'

export const getCookieTextTemplates = () => configApi.get('/api/CookieTextTemplates').then(({ data: { cookieTextTemplates } }) => ({
	cookieTextTemplates: cookieTextTemplates.map(textTemplate => ({
		...textTemplate,
		gpcBanner: {
			gpcBannerTitle: textTemplate.gpcBanner.gpcBannerTitle,
			gpcBannerText: textTemplate.gpcBanner.gpcBannerText,
			acceptAllButtonText: textTemplate.gpcBanner.acceptAllButtonText,
			acceptAllExcGpcButtonText: textTemplate.gpcBanner.acceptAllExcGpcButtonText,
			backButtonText: textTemplate.gpcBanner.backButtonText
		},
		isSystem: textTemplate.isSystem,
		createdDate: format(new Date(textTemplate.createdDate), DATE_FORMAT)
	}))
}))

export const postCookieTextTemplate = textTemplate => configApi.post('/api/CookieTextTemplates', textTemplate)

export const putCookieTextTemplate = textTemplate => configApi.put('/api/CookieTextTemplates', textTemplate)

export const deleteCookieTextTemplate = textTemplateId => configApi.delete(`/api/CookieTextTemplates/${textTemplateId}`)

export const postCookieTextTemplateTranslation = translation => configApi.post(`/api/CookieTextTemplates/CreateTranslation`, translation)

export const putCookieTextTemplateTranslation = translation => configApi.put(`/api/CookieTextTemplates/UpdateTranslation`, translation)

export const deleteCookieTextTemplateTranslation = templateTranslationId => configApi.delete(`/api/CookieTextTemplates/DeleteTranslation/${templateTranslationId}`)
