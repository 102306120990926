<template>
	<SectionCard>
		<template #title>
			Banner {{ translationMode ? 'Translations' : '' }}
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:label="`User Consent Tab Label Text${!translationMode ? ' *' : ''}`"
						:value.sync="banner.userConsentTabLabelText"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguageBanner.userConsentTabLabelText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('userConsentTabLabelText', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						:label="`Legitimate Interest Tab Label Text${!translationMode ? ' *' : ''}`"
						:value.sync="banner.legitimateInterestTabLabelText"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguageBanner.legitimateInterestTabLabelText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('legitimateInterestTabLabelText', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:label="`Opt In Label${!translationMode ? ' *' : ''}`"
						:value.sync="banner.optInLabel"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguageBanner.optInLabel : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('optInLabel', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						:label="`Opt Out Label${!translationMode ? ' *' : ''}`"
						:value.sync="banner.optOutLabel"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguageBanner.optOutLabel : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('optOutLabel', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:label="`Accept All Button Text${!translationMode ? ' *' : ''}`"
						:value.sync="banner.acceptAllButtonText"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguageBanner.acceptAllButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('acceptAllButtonText', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						:label="`Reject All Button Text${!translationMode ? ' *' : ''}`"
						:value.sync="banner.rejectAllButtonText"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguageBanner.rejectAllButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('rejectAllButtonText', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:label="`Submit Preferences Button Text${!translationMode ? ' *' : ''}`"
						:value.sync="banner.submitPreferencesButtonText"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguageBanner.submitPreferencesButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('submitPreferencesButtonText', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
export default {
	components: {
		SectionCard,
		TextField
	},
	props: {
		banner: Object,
		userCanCreateUpdate: Boolean,
		defaultLanguageBanner: Object,
		translationMode: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		updateBanner (property, value) {
			this.$emit('update:banner', {
				...this.banner,
				[property]: value
			})
		}
	}
}
</script>
