<template>
	<SectionCard>
		<template #title>
			GPC Banner {{ translationMode ? 'Translations' : '' }}
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="12">
					<TextField
						label="GPC Banner Title"
						:value.sync="gpcBanner.gpcBannerTitle"
						:tooltip-text="translationMode ? defaultLanguageGpcBanner.gpcBannerTitle : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateGpcBanner('gpcBannerTitle', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<Froala
						v-if="userCanCreateUpdate"
						:value.sync="gpcBanner.gpcBannerText"
						title="GPC Banner Text"
						:rules="{max: 2000}"
						:tooltip-text="translationMode ? defaultLanguageGpcBanner.gpcBannerText : ''"
						@input="updateGpcBanner('gpcBannerText', $event)"
					/>
					<div
						v-else
						v-dompurify-html="gpcBanner.gpcBannerText"
						class="mt-2 cassie-disabled-textarea rounded-lg"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="4">
					<TextField
						label="Accept All Button Text"
						:value.sync="gpcBanner.acceptAllButtonText"
						:tooltip-text="translationMode ? defaultLanguageGpcBanner.acceptAllButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateGpcBanner('acceptAllButtonText', $event)"
					/>
				</v-col>
				<v-col cols="4">
					<TextField
						label="Accept All Exc GPC Button Text"
						:value.sync="gpcBanner.acceptAllExcGpcButtonText"
						:tooltip-text="translationMode ? defaultLanguageGpcBanner.acceptAllExcGpcButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateGpcBanner('acceptAllExcGpcButtonText', $event)"
					/>
				</v-col>
				<v-col cols="4">
					<TextField
						label="Back Button Text"
						:value.sync="gpcBanner.backButtonText"
						:tooltip-text="translationMode ? defaultLanguageGpcBanner.backButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updateGpcBanner('backButtonText', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Froala from '../../../../shared/froala-editor.vue'
export default {
	components: {
		SectionCard,
		Froala,
		TextField
	},
	props: {
		gpcBanner: Object,
		userCanCreateUpdate: Boolean,
		defaultLanguageGpcBanner: Object,
		translationMode: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		updateGpcBanner (property, value) {
			this.$emit('update:gpcBanner', {
				...this.gpcBanner,
				[property]: value
			})
		}
	}
}
</script>
<style scoped>
::v-deep .theme--light.v-input.read-only-textarea,
>>> .theme--light.v-input.read-only-textarea,
.theme--light.v-input.read-only-textarea input,
.theme--light.v-input.read-only-textarea textarea {
  color: var(--global-cassie-disabled-textarea-color) !important;
}
</style>
