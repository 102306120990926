<template>
	<SectionCard>
		<template #title>
			Pre-Banner {{ translationMode ? 'Translations' : '' }}
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="12">
					<Froala
						v-if="userCanCreateUpdate"
						:value.sync="preBanner.preBannerText"
						:title="`Pre Banner Text${!translationMode ? ' *' : ''}`"
						:rules="{required: !translationMode, max: 2000}"
						:tooltip-text="translationMode ? defaultLanguagePreBanner.preBannerText : ''"
						@input="updatePreBanner('preBannerText', $event)"
					/>
					<v-textarea
						v-else
						v-dompurify-html="preBanner.preBannerText"
						light
						dense
						outlined
						readonly
						class="mt-2 cassie-disabled-textarea read-only-textarea"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="4">
					<TextField
						:label="`Accept All Button Text${!translationMode ? ' *' : ''}`"
						:value.sync="preBanner.acceptAllButtonText"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguagePreBanner.acceptAllButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updatePreBanner('acceptAllButtonText', $event)"
					/>
				</v-col>
				<v-col cols="4">
					<TextField
						:label="`Reject All Button Text${!translationMode ? ' *' : ''}`"
						:value.sync="preBanner.rejectAllButtonText"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguagePreBanner.rejectAllButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updatePreBanner('rejectAllButtonText', $event)"
					/>
				</v-col>
				<v-col cols="4">
					<TextField
						:label="`Cookie Settings Button Text${!translationMode ? ' *' : ''}`"
						:value.sync="preBanner.cookieSettingsButtonText"
						:rules="{ required: !translationMode }"
						:tooltip-text="translationMode ? defaultLanguagePreBanner.cookieSettingsButtonText : ''"
						:disabled="!userCanCreateUpdate"
						@input="updatePreBanner('cookieSettingsButtonText', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Froala from '../../../../shared/froala-editor.vue'
export default {
	components: {
		SectionCard,
		Froala,
		TextField
	},
	props: {
		preBanner: Object,
		userCanCreateUpdate: Boolean,
		defaultLanguagePreBanner: Object,
		translationMode: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		updatePreBanner (property, value) {
			this.$emit('update:preBanner', {
				...this.preBanner,
				[property]: value
			})
		}
	}
}
</script>
<style scoped>
/deep/ .theme--light.v-input.read-only-textarea,
>>> .theme--light.v-input.read-only-textarea,
.theme--light.v-input.read-only-textarea input,
.theme--light.v-input.read-only-textarea textarea {
  color: var(--global-cassie-disabled-textarea-color) !important;
}
</style>
